import React, { useEffect, useState } from "react";
import "./DieselHydProductList.css" 
import Text from "../Text/Text";
import Image from "../Image/Image";
import Button from "../Button/Button";

export default function DieselHydProductList() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add the "show" class after the component mounts to trigger the slide-in effect
    setIsVisible(true);
  }, []);

  const ProductProvides = [
    "Prevents wear, oxidation and corrosion",
    "Tasteless and odorless",
    "Anti-foaming capabilities",
    "Very good thermal and temperature capability under different temperatures",
    "Good water separation ability",
]

  return (
    <div className={`DieselHydProductList  ${isVisible ? "show" : ""}`}>
      {/* <Button Src={"/images/PaoSeriesPage/0w-20Pao.webp"} RouteLink={"0w20-pao"}  /> */}
      <div className="Pao-0w20Parent d-flex">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <div className="ProductSpecificatons">
          <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - HYDRAULIC OIL " FontSize={"4rem"} FontWeight={"600"}   />
          <Text text="HYD-68"  />
          <Text Margin={"25px 0px 20px 0px"} FontSize={"1.8rem"}  text="Hagan Hydraulic HY-68 is a part of Hagan premium-grade and mineral-based hydraulic oils having excellent anti-wear qualities and provides protection in extreme challenging situations while following ISO for hydraulic oils.
" />
          <Text FontSize={"1.8rem"} text="It helps in resistance against rust, corrosion and oxidation. It is designed for application in food and beverages industry where there is a chance of exposure of the lubricated portion of machinery to edible product and packaging." />
        </div>
        <div className="ProductProvides">
        <Text text="HAGAN - HYD 68 Provides" Margin={"25px 0px 25px 0px"} FontSize={"2.5rem"} FontWeight={"500"} />
        <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
      <Image Src={"/images/DieselProduct/heavy-hyd-68.png"}   />
      </div>
    </div>
      
    </div>
  );
}
