import React from "react";
import Button from "../Button/Button";

export default function GearProductList() {
  return (
    <>
      <div className="Product_List GearProductList">
        <Button
          ImgWidth={"100%"}
          Src={"/images/GearProduct/Gear-G-80-80W90.png"}
          RouteLink={"/product/gear-series/g-80-80w90"}
        />
        <Button
          ImgWidth={"100%"}
          Src={"/images/GearProduct/Gear-G-85-85W140.png"}
          RouteLink={"/product/gear-series/g-85-85w140"}
        />
        <Button
          ImgWidth={"100%"}
          Src={"/images/GearProduct/gear-G-140-GL-4-p-500.png"}
          RouteLink={"/product/gear-series/g_140"}
        />
      </div>
    </>
  );
}
