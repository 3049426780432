import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function G_85_85w140() {
  const ProductProvides = [
    "Protects gear against corrosion and scuffing",
    "Provides protections against ferrous and non ferrous elements",
    "Provides oxidation resistance and oil thickening",
    "Helps in longer service life",
    "Facilitates in good lubrication without cleaning at less temperatures",
    "Optimal for heavy duty service in trucks and buses operating under extreme temperatures",
  ];
  return (
    <div className="Pao-0w20Parent d-flex">
      <div className="ProductDetails" style={{ width: "40%" }}>
      <div className="ProductDetailsInner">

        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/gear-series"
        />
        <div className="ProductSpecificatons">
          <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - G-85" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="85W140 - GL-5" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="A high performance gear oil having multiple applications for pressure along tooth face. It is made out of premium quality mineral all with an adequate mix of agents. Provides high load-bearing capacity, protects against wear and oxidation stability according to the API classification GL-5."
          />
          <Text
            FontSize={"1.8rem"}
            text="It is developed for use in heavy loaded, hypoid gears and as well as for axle, transfer case, transmission gear, auxiliary gear boxes in vehicles and machinery. It is also applicable in high pressure gear oils where this grade of lubricant is recommended."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="HAGAN - G-85 85W140 Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
          />
          <ul>
            {ProductProvides.map((item) => (
              <li>
                <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
      <div className="ProductImage">
        <Image Src={"/images/GearProduct/Gear-G-85-85W140.png"} />
      </div>
    </div>
  );
}
