import React, { useState } from 'react';
import "../SearchComp/SearchComp.css"

const SearchFilter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [matchingProducts, setMatchingProducts] = useState([]);

  const handleSearch = () => {
    const matchingProduct = products.find(
      (product) => product.name.toLowerCase() === searchQuery.toLowerCase()
    );
    if (matchingProduct) {
      // Open corresponding page based on matchingProduct.page
      window.open(matchingProduct.page, '_self');
    }
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchQuery(searchTerm);

    if (searchTerm === '') {
      setMatchingProducts([]); // Hide suggestions when search input is empty
    } else {
      const matchingProducts = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm)
      );
      setMatchingProducts(matchingProducts);
    }
  };

  const handleItemClick = (productName) => {
    setSearchQuery(productName);
  };

  const products = [
    { name: 'GT_5_5w30', page: '/product/semi-synthetic-series/5w30-gt-5' },
    { name: 'GT_6_5w40', page: '/product/semi-synthetic-series/5w40-gt-6' },
    { name: 'HP_N_20w50', page: '/product/semi-synthetic-series/5w50-hp-n' },

    { name: 'HP_1_20w50', page: '/product/minerals/hp-1-20w50' },
    { name: 'HP_40_10w40', page: '/product/minerals/hp-40-10w40' },
    { name: 'MS_1_20w50', page: '/product/minerals/ms-1-20w50' },

    { name: 'Diesel_HP_7_20W50', page: '/product/diesel_series/hp-7-20w50' },
    { name: 'Diesel_HP_9_15W40', page: '/product/diesel_series/hp-9-15w40' },
    { name: 'Diesel_HP_60_20W60', page: '/product/diesel_series/hp-60-20w60' },
    { name: 'Diesel_MS_9_SAE50', page: '/product/diesel_series/ms-9-sae50' },
    { name: 'Diesel_HYD_68', page: '/product/diesel_series/hyd-68' },

    { name: 'Bike_4T_J_20W50', page: '/product/motorcycle_series/4t_j_20w50' },
    { name: 'Bike_4T_J2_20W50', page: '/product/motorcycle_series/4t_j2_20w50' },

    { name: 'G_8_80w90', page: '/product/gear-series/g-80-80w90' },
    { name: 'G_85_85w140', page: '/product/gear-series/g-85-85w140' },
    { name: 'G_140', page: '/product/gear-series/g_140' },

    { name: 'CV_95', page: '/product/transmission_series/atf_cv_95' },
    { name: 'Atf_Multi', page: '/product/transmission_series/atf_uhm' },
    { name: 'Atf_Uh_6', page: '/product/transmission_series/atf_uh_6' },
    { name: 'Atf_Uh_3', page: '/product/transmission_series/atf_uh_3' },






    

  ];

  return (
    <div className='searchDiv'>
      <div className='searchSec'>
      <input
        type="text"
        value={searchQuery}
        onChange={handleInputChange}
        placeholder="Search Products"
      />
      <i onClick={handleSearch}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>
      </i>
      {/* Display matching product suggestions */}
      {matchingProducts.length > 0 && (
        <div className="suggestionContainer">
          
          {matchingProducts.map((product) => (
            <div
              key={product.name}
              onClick={() => handleItemClick(product.name)}
            >
              {product.name}
            </div>
          ))}
        </div>
      )}
      </div>
      
    </div>
  );
};

export default SearchFilter;
