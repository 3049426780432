import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function HP_1_20w50() {
  const ProductProvides = [
    "Anti-wear additive system provides protection against wear under all working conditions",
    "Multi-grade viscosity provides protection in higher operating temperatures",
    "Grants the engine long life",
    "Helps in attaining maximum engine strength and high performance by providing resistance against sludge formation",
    "Higher viscosity index results in lower oil consumption",
  ];
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
          <Button
            BGcolor={"white"}
            Radius={"50%"}
            Width={"50px"}
            Height={"50px"}
            text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
            RouteLink="/product/minerals"
          />
          <div className="ProductSpecificatons">
            <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
            <Text text="HAGAN - HP-1" FontSize={"4rem"} FontWeight={"600"} />
            <Text text="20W50 - SL" />
            <Text
              Margin={"25px 0px 20px 0px"}
              FontSize={"1.8rem"}
              text="HAGAN SL 20W-50 is a premium performance mineral engine oil that is recommended for gasoline, LPG and diesel engine vehicles such as modern cars and light vans that may or may not have turbo-chargers. This oil has excellent anti-wear, anti-oxidant, anti-foaming, detergent and diffusing qualities and it surpasses the performance criteria of API SL. Higher thermal stability and oxidation resistance helps in protection against oil degradation that leads to sludge formation on pistons, crankcase and valve train."
            />
            <Text
              FontSize={"1.8rem"}
              text="It is best suited for naturally aspirated gasoline engines in passenger car, commercial vehicles and 4-storke gasoline engines incompact power equipment if recommended by the manufacturer of the equipment for this grade oil. Whereas, it is not suitable for use in engines that need ILSAC or ACEA-classified oils."
            />
          </div>
          <div className="ProductProvides">
            <Text
              text="HAGAN - HP-1  20W-50 SL Provides"
              Margin={"25px 0px 25px 0px"}
              FontSize={"2.5rem"}
            />
            <ul>
              {ProductProvides.map((item) => (
                <li>
                  <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
<div className="ProductImage">
        <Image Src={"/images/Minerals/Mineral HP-1.png"} Width={"65%"} />
      </div>
    </div>
  );
}
