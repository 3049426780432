import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Diesel_HP_7_20W50() {

    const ProductProvides = [
        "Premium low temperature capability which helps in good engine start up in cold weather.",
        "It has premium engine cleaning capability",
        "It has lower combustion residues",
        "It provides excellent engine protection across multiple driving scenarios because of its high and low temperature qualities",
        "It delivers premium rust and corrosion protection",
        "The dispersant in the oil provide premium control over oil thickening because of induced soot"
    ]
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/diesel_series"

        />
        <div className="ProductSpecificatons">
          <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - HP-7" FontSize={"4rem"} FontWeight={"600"}   />
          <Text text="20W50 - CF-4/SL"  />
          <Text Margin={"25px 0px 20px 0px"} FontSize={"1.8rem"}  text="HAGAN 20W50 - CF-4 is built with cleansing technology to restrict dirt and sludge accumulation resulting in protection and prolonged life of the engine. It is made for variety of automobiles, multi-valve, modern, high output, turbo and supercharged diesel-powered passenger cars and light duty vehicle engines powered by diesel. This oil is made for vehicles and light truck engines that require good performance in all operating conditions." />
          <Text FontSize={"1.8rem"} text="It is made with high viscosity index using excellent additive technology and efficient detergent additive that provides greater engine protection under extreme operating conditions." />
        </div>
        <div className="ProductProvides">
        <Text text="HAGAN - HP-7 20W-50 CF-4/SL Provides" Margin={"25px 0px 25px 0px"} FontSize={"2.5rem"}  FontWeight={"500"} />
        <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
      <Image Src={"/images/DieselProduct/heavy-HP-7-20W50.png"} Width={"65%"}   />
      </div>
    </div>
  );
}
