import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Diesel_HP_9_15W40() {

    const ProductProvides = [
        "Premium quality levels for longer oil drain gaps",
        "Recommended for turbocharged and supercharged engines",
        "Stellar anti-oxidation ability and thermal stability",
        "More viscosity stability results in excellent lubrication at lower temperature start up",
        "It comprises of best anti-corrosion and anti-wear agents",
        "As a result of oxidation and combustion neutral acids are released which prolong the engine life",
        "Results in low maintenance cost",
        "Results in low maintenance cost"
    ]
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/diesel_series"

        />
        <div className="ProductSpecificatons">
          <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - HP-9" FontSize={"4rem"} FontWeight={"600"}   />
          <Text text="HAGAN - 15W-40 - CI-4/SL"  />
          <Text Margin={"25px 0px 20px 0px"} FontSize={"1.8rem"}  text="Hagan 15W40 - CI-4/SL is a heavy duty engine oil used in a wide range of diesel and gasoline engines which gives premium protection and helps in avoiding corrosion, oil thickening because of soot build up, deposits on piston, accumulation of sludge, oxidation and provides higher temperature stability. It is suitable for heavy duty trucking, mining, construction equipment and works effectively under extreme temperatures and climates." />
          <Text FontSize={"1.8rem"} text="It is developed for use in both turbocharged engines as well as diesel and gasoline engines. It is recommended for use in propane engines, gasoline, turbocharged or supercharged diesel engines. Hagan 15W40 - CI-4/SL has been designed to meet the requirements Exhaust Gas Recirculation (EGR) engines." />
        </div>
        <div className="ProductProvides">
        <Text text="HAGAN - 15W-40 - CI-4/SL Provides" Margin={"25px 0px 25px 0px"} FontSize={"2.5rem"} FontWeight={"500"} />
        <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
      <Image Src={"/images/DieselProduct/heavy-HP-9-15W40.png"} Width={"65%"}   />
      </div>
    </div>
  );
}
