import React from "react";
import Button from "../Button/Button";
import "./Navbar.css";
import { useState } from "react";
import SearchFilter from "../SearchComp/SearchComp";
import Dropdown from "react-bootstrap/Dropdown";
import SideNav from "../SideNav/SideNav";

export default function Navbar({LogoSrc, FontColor}) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isGasolineOpen, setIsGasolineOpen] = useState(false);
  const [isDieselOpen, setIsDieselOpen] = useState(false);


  const toggleSearchBar = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleMobileMenu = () => {
    const menuToggle = document.getElementById('menu__toggle');
    if (menuToggle.checked) {
      menuToggle.checked = false;
    }
    setIsDieselOpen(false);
    setIsGasolineOpen(false);
    };

  const toggleGasolineDropdown = () => {
    setIsGasolineOpen(!isGasolineOpen);
    setIsDieselOpen(false);
  };

  const toggleDieselDropdown = () => {
    setIsDieselOpen(!isDieselOpen);
    setIsGasolineOpen(false);
  };



  return (
    <div className="NavbarParent d-flex justify-content-between">
      <div className="NavbarLogo">
        <Button
          Src={LogoSrc}
          RouteLink={"/"}
          Color={"white"}
        />
      </div>
      <div className="NavbarItems ">
        <Button
          RouteLink={"/product"}
          text="product"
          TextTransform={"uppercase"}
          FontSize={"1.5rem"}
          Color={FontColor }
        />

        <Button
          RouteLink={"/contact_us"}
          text="contact us"
          TextTransform={"uppercase"}
          FontSize={"1.5rem"}
          Margin={"0px 45px 0px 45px"}
          Color={FontColor}
        />
        <Button
          RouteLink={"https://shophaganlubricants.com/"}
          text="order now"
          TextTransform={"uppercase"}
          FontSize={"1.5rem"}
          Margin={"0px 45px 0px 0px"}
          Color={FontColor}
        />
        <button className="search_button" onClick={toggleSearchBar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
          </svg>
        </button>
        {/* <Button Src={"/images/searchIcon.svg"} /> */}

        {isSearchOpen && <SearchFilter />}
      </div>

      {/*******************************************
      *************** MOBILE MENU ***************
      ********************************************/}

      <div class="hamburger-menu">
        <input id="menu__toggle" type="checkbox" />
        <label class="menu__btn" for="menu__toggle">
          <span></span>
        </label>
        <div className="menu__box">
          <div className="mob_menu_logo">
            <Button
              Src={"/images/hagan_logo.svg"}
              RouteLink={"/"}
              Width={"30%"}
              ImgWidth={"100%"}
              Color={"white"}
              Margin={"25px"}
            />
          </div>
          <ul>
            <li>
              <Dropdown className="ProductDropdown">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic ProductButton"
                  className="Mobile_Product_Tab"
                  style={{fontSize:"2.2rem"}}
                >
                  PRODUCT
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown>
                    <li className="Mobile_Gasoline_Tab">
                      <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={toggleGasolineDropdown} style={{fontSize:"2rem"}}>
                        GASOLINE Engine OIL
                      </Dropdown.Toggle>
                    </li>
                      {/* GASOLINE DRPPDOWN */}
                    <Dropdown.Menu show={isGasolineOpen} className="Gasoline_dropdown">
                      <li>
                        <Button
                          RouteLink={"/product/pao_series"}
                          text="PAO Series"
                          TextTransform={"uppercase"}
                          FontSize={"1.8rem"}
                          Margin={"10px 45px 0px 0px"}
                          Color={"white"} 
                          handleClick={toggleMobileMenu}

                        />
                      </li>
                      <li>
                        <Button
                           RouteLink={"/product/semi-synthetic-series"}
                          text="Synthetic Series / Semi Synthetic Series"
                          TextTransform={"uppercase"}
                          FontSize={"1.8rem"}
                          Margin={"10px 45px 0px 0px"}
                          Color={"white"}
                          handleClick={toggleMobileMenu}
                        />
                      </li>
                      <li className="MobMineralLink">
                        <Button
                          RouteLink="/product/minerals"
                          text="Minerals / Premium Mineral"
                          TextTransform={"uppercase"}
                          FontSize={"1.8rem"}
                          Margin={"10px 45px 0px 0px"}
                          Color={"white"}
                          handleClick={toggleMobileMenu}

                        />
                      </li>
                    </Dropdown.Menu>

                    <li className="Mobile_Gasoline_Tab">
                      <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={toggleDieselDropdown} style={{fontSize:"2rem"}}>
                        DIESEL ENGINE OIL
                      </Dropdown.Toggle>
                    </li>
                    {/* DIESEL DRPPDOWN */}
                    <Dropdown.Menu show={isDieselOpen} className="Diesel_dropdown">
                      <li>
                        <Button
                          RouteLink={"/product/diesel_series"}
                          text="HP Series"
                          TextTransform={"uppercase"}
                          FontSize={"1.8rem"}
                          Margin={"10px 45px 0px 0px"}
                          Color={"white"} 
                          handleClick={toggleMobileMenu}

                        />
                      </li>
                      <li>
                        <Button
                          RouteLink="/product/diesel_series/hyd-68"
                          text="Hydraulic series"
                          TextTransform={"uppercase"}
                          FontSize={"1.8rem"}
                          Margin={"10px 45px 0px 0px"}
                          Color={"white"}
                          handleClick={toggleMobileMenu}
                        />
                      </li>
                    </Dropdown.Menu>

                  
                    <li>
                      <Button
                        RouteLink={"/product/motorcycle_series"}
                        text="MOTOR CYCLE ENGINE OIL"
                        TextTransform={"uppercase"}
                        FontSize={"2rem"}
                        Margin={"10px 45px 0px 0px"}
                        Color={"white"}
                        handleClick={toggleMobileMenu}
                      />
                    </li>
                    <li>
                      <Button
                        RouteLink={"/product/gear-series"}
                        text="GEAR OIL"
                        TextTransform={"uppercase"}
                        FontSize={"2rem"}
                        Margin={"10px 45px 0px 0px"}
                        Color={"white"}
                        handleClick={toggleMobileMenu}
                      />
                    </li>
                    <li className="MobTransmissionTab">
                      <Button
                        RouteLink={"/product/transmission_series"}
                        text="TRANSMISSION ENGINE OIL"
                        TextTransform={"uppercase"}
                        FontSize={"2rem"}
                        Margin={"10px 45px 0px 0px"}
                        Color={"white"}
                        handleClick={toggleMobileMenu}
                      />
                    </li>
                  </Dropdown>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li>
              <Button
                RouteLink={"/contact_us"}
                text="contact us"
                TextTransform={"uppercase"}
                FontSize={"2.2rem"}
                Margin={"10px 45px 0px 0px"}
                Color={"white"}
                handleClick={toggleMobileMenu}
              />
            </li>
            <li>
              <Button
                RouteLink={"/order_now"}
                text="order now"
                TextTransform={"uppercase"}
                FontSize={"2.2rem"}
                Margin={"10px 45px 0px 0px"}
                Color={"white"}
                handleClick={toggleMobileMenu}
              />
            </li>
            <li className="MobileDownloadProfile">
            <a className='DownloadProfileLink' href="/images/HAGAN-Catalogue New 2023.pdf" target='blank' >Download <span>Product</span> Profile</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
