import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Atf_Multi() {
  const ProductProvides = [
    "It provides anti shudder protection.",
    "Premium oxidation and thermal stability.",
    "Facilitates in high foam stability.",
    "Has more stable friction capability.",
    "Has low temperature stability.",
    "Provides help in start-up in cold environments",
    "Extended fluid and long transmission life.",
    "Provides resistance to chemical deterioration.",
    "Helps in smooth shifting and wear protection.",
    "Protects against rust and corrosion.",
  ];
  return (
    <div className="Pao-0w20Parent d-flex justify-content-around">
      <div className="ProductDetails" style={{ width: "40%" }}>
      <div className="ProductDetailsInner">

        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/transmission_series"
        />
        <div className="ProductSpecificatons">
          <Text text="Transmission Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - UHM" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="ATF - MULTI" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="HAGAN ATF MULTI is an excellent quality, fully synthetic multi-vehicle fluid transmission having multiple purposes for automatic gear boxes. It has specialized enhanced additives. ATF Multi is a special transmission oil developed to cater for the requirements of wide range of automatic transmissions requiring SP-II, SP-III, DEXRON-III and Allison C-4 standards. Its high viscosity index helps in less oil consumption by facilitating in less oil thinning in high temperatures and less oil thickening in cold temperatures. It is not suitable for CVT or DCT transmissions."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="HAGAN - UHM ATF-MULTI Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
          />
          <ul>
            {ProductProvides.map((item) => (
              <li>
                <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
      <div className="ProductImage">
        <Image Src={"/images/TransmissionProduct/ATF-UHM.png"} Width={"55%"} />
      </div>
    </div>
  );
}
