import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Bike_4T_J2_20W50() {
  const ProductProvides = [
    "Made out of quality mineral oils and premium additive systems",
    "Helps to keep the engine clean and protects from corrosion",
    "Protects the engine and gear box in one circulation system",
    "Prevent slippage of clutch",
  ];
  return (
    <div className="Pao-0w20Parent d-flex">
      <div className="ProductDetails" style={{ width: "40%" }}>
      <div className="ProductDetailsInner">
        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/motorcycle_series"
        />
        <div className="ProductSpecificatons">
          <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - 4T-J2" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="20W50 - SJ" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="HAGAN 20W-50 JASO MA 2 is a high performing and multi-grade lubricant used in high-RPM, 4-stroke motorcycleengines that have built-in gear boxes and wet clutches. It can also be used in any engines that require 4-stroke engine oilfulfilling the condition of JASO MA2, JASO MA or API SJ. Although it is developed for latest engines but it can also fulfill the needsof older engines and is aligned with motorcycle engine technology standards determined by OEM’s."
          />
          <Text
            FontSize={"1.8rem"}
            text="It provides engine protection from heat-activated anti-wear molecule and defends the engine from start-up up till hightemperature situations caused by longer drives. It is suitable for modern motorcycle engine JASO MA or JASO MA 2 determinedby OEM’s."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="HAGAN - 4T-J2 20W50 Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
          />
          <ul>
            {ProductProvides.map((item) => (
              <li>
                <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
      <div className="ProductImage">
        <Image
          Src={"/images/MotorCycleProduct/bike-20W50-SJ-22.png"}
          width={"100%"}
        />
      </div>
    </div>
  );
}
