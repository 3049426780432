import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Diesel_MS_9_SAE50() {

    const ProductProvides = [
        "It saves on maintenance through anti-wear additive systems that forms protective layer on contact surfaces inall working conditions",
        "It helps in resistance of formation of high temperature deposits in diesel engines",
        "Excellent oxidation stability helps in protection against oil thickening",
        "Metallic detergent additive system retains performance and strength by facilitating excellent control over piston and ring deposits",
        "Metallic detergent additive system retains performance and strength by facilitating excellent control over piston and ring deposits",
    ]
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/diesel_series"

        />
        <div className="ProductSpecificatons">
          <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - MS-9" FontSize={"4rem"} FontWeight={"600"}   />
          <Text text="SAE 50 - CF/SF"  />
          <Text Margin={"25px 0px 20px 0px"} FontSize={"1.8rem"}  text="Hagan SAE 50 - CF/SF is a high performing mono grade energized protection oil with a track record of lubricant chemistry which adapts itself according to your driving style so that you get maximum protection regardless of whatever the requirements your engine or equipment has. It is suited for diesel engines formulated from premium quality base oils and additive system. More specifically they are optimized for performance in inter-cooled and turbocharged engines working under extreme on and off highway situations." />
          <Text FontSize={"1.8rem"} text="It is best suited for engines requiring API, CF or SF performance oils. Alkaline reserve level in it makes it feasible for usage with high sulfur fuels at optimum oil drain levels. It is usable in mixed fleet of diesel engines (high-speed ,four-stroke, turbocharged or naturally aspirated), gasoline engines and commercial transport engines." />
        </div>
        <div className="ProductProvides">
        <Text text="HAGAN - SAE 50 - CF/SF provides" Margin={"25px 0px 25px 0px"} FontSize={"2.5rem"} FontWeight={"500"} />
        <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
      <Image Src={"/images/DieselProduct/MS-9.png"}   Width={"65%"}/>
      </div>
    </div>
  );
}
