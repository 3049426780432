import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./OrderNow.css";
import "../ContactPage/ContactPage.css"
import Text from "../../Components/Text/Text";
import { RotatingLines } from "react-loader-spinner";
import { Helmet } from 'react-helmet';


export default function OrderNow() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const bodyRef = useRef(document.body);



      

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("Not Select");
  const [selectedName, setSelectedName] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedNumber, setSelectedNumber] = useState('');
  const [selectedMessage, setSelectedMessgage] = useState('');

  const [selectedSize, setSelectedSize] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');


  const inputRef = useRef(null);
  const emailRef = useRef(null);
  const numberRef = useRef(null);
  const messageRef = useRef(null);

  const sizeRef = useRef(null);
  const quatityRef = useRef(null);
  const paymentRef = useRef(null);



    // CODE FOR PAGE SLIDE ANIMATION
    useEffect(() => {
      // Add the "show" class after the component mounts to trigger the slide-in effect
      setIsVisible(true);
  
      // Remove the overflow-x: hidden when the animation completes
      return () => {
        bodyRef.current.style.overflowX = "auto";
      };
  
  
    }, []);
  
    useEffect(() => {
      // Hide the scrollbar on the x-axis when the slide-in animation is active
      if (isVisible) {
        bodyRef.current.style.overflowX = "hidden";
      }
      
    });
      // CODE FOR PAGE SLIDE ANIMATION END 


  useEffect(() => {
    if (selectedName && inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedName]);

  useEffect(() => {
    if (selectedEmail && emailRef.current) {
      emailRef.current.focus();
    }
  }, [selectedEmail]);


  useEffect(() => {
    if (selectedNumber && numberRef.current) {
      numberRef.current.focus();
    }
  }, [selectedNumber]);


  useEffect(() => {
    if (selectedMessage && messageRef.current) {
      messageRef.current.focus();
    }
  }, [selectedMessage]);

  useEffect(() => {
    if (selectedSize && sizeRef.current) {
      sizeRef.current.focus();
    }
  }, [selectedSize]);

  useEffect(() => {
    if (selectedQuantity && quatityRef.current) {
      quatityRef.current.focus();
    }
  }, [selectedQuantity]);

  useEffect(() => {
    if (selectedPayment && paymentRef.current) {
      paymentRef.current.focus();
    }
  }, [selectedPayment]);




  const handleNameChange = (e) => {
    setSelectedName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setSelectedEmail(e.target.value);
  };

  const handleNumberChange = (e) => {
    setSelectedNumber(e.target.value);
  };

  const handleMessageChange = (e) => {
    setSelectedMessgage(e.target.value);
  };

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const handleQuatityChange = (e) => {
    setSelectedQuantity(e.target.value);
  };

  const handlePaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };





  
  // ARRAY OF CATEGORY
  const category = ["MCO", "PCMO", "DIESEL", "GREASE", "ATF"];

  // ARRAY OF products against MCO category
  const mcoProduct = ["20W50-SJ(JASO-MA)", "20W50-SJ(JASO-MA2)"];
  // ARRAY OF products against MCO category
  const pcmoProduct = [
    "0W20-SP(GT-1)",
    "5W30-SP(GT-5)",
    "10W40-SP(GT-6)",
    "20W50-SN(HP-N)",
    "10W40-SL/CF(HP-40)",
    "20W40-SL(HP-1)",
    "20W50-SG/CD(MS-1)",
  ];
  // ARRAY OF products against DDIESEL category
  const dieselProduct = [
    "15W40-CI-4(HP-9)",
    "20W50-CF-4(HP-7)",
    "20W60-CF-4/SL(HP-60)",
    "SAE-50-CF/SF(MS-9)",
    "HYD-68",
  ];
  // ARRAY OF products against GREASE category
  const greaseProduct = [
    "80W90-GL-4(G-80)",
    "85W140-GL-5(G-85)",
    "G-140(GL-4)",
  ];
  // ARRAY OF products against ATF category
  const atfProduct = [
    "CVTF-MULTI(CV-95)",
    "UHM-ATF-MULTI",
    "UH-6(ATF-DEXRON-VI)",
    "UH-3(ATF-DEXRON-III)",
  ];


  // OBJECT OF SIZES
  const productOptions = {
    "20W50-SJ(JASO-MA)": ["700ML"],
    "20W50-SJ(JASO-MA2)": ["700ML", "1L"],

    // PCMO
    "0W20-SP(GT-1)": ["1L","4L"],
    "5W30-SP(GT-5)": ["1L","3L","4L"],
    "10W40-SP(GT-6)": ["1L","3L","4L"],
    "20W50-SN(HP-N)":["1L","3L","4L"],
    "10W40-SL/CF(HP-40)":["3L","4L"],
    "20W40-SL(HP-1)":["1L","3L","4L"],
    "20W50-SG/CD(MS-1)":["1L","3L","4L"],

    // DIESEL
    "15W40-CI-4(HP-9)": ["1L","10L","208L"],
    "20W50-CF-4(HP-7)": ["1L","8L","10L","14L","208L"],
    "20W60-CF-4/SL(HP-60)": ["4L","5L","208L"],
    "SAE-50-CF/SF(MS-9)": ["4L","8L","208L"],
    "HYD-68": ["20L","208L"],

    // GREASE
    "80W90-GL-4(G-80)": ["1L"],
    "85W140-GL-5(G-85)": ["1L","4L"],
    "G-140(GL-4)": ["1L","4L"],

    // ATF
    "CVTF-MULTI(CV-95)": ["1L","4L"],
    "UHM-ATF-MULTI": ["1L"],
    "UH-6(ATF-DEXRON-VI)": ["1L"],
    "UH-3(ATF-DEXRON-III)": ["1L"],


    // Add more product options as needed
  };



  function handleCategoryChange(event) {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);

  }

  function handleProductChange(event) {
    setSelectedProduct(event.target.value);
  }

  function ContactForm() {
    const [formData, setFormData] = useState({});

    function validEmail(email) {
      var re =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      return re.test(email);
    }

    function validateHuman(honeypot) {
      if (honeypot) {
        console.log("Robot Detected!");
        return true;
      } else {
        console.log("Welcome Human!");
      }
    }

    function getFormData(form) {
      var elements = form.elements;

      var fields = Object.keys(elements)
        .filter(function (k) {
          return elements[k].name !== "honeypot";
        })
        .map(function (k) {
          if (elements[k].name !== undefined) {
            return elements[k].name;
          } else if (elements[k].length > 0) {
            return elements[k].item(0).name;
          }
        })
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos && item;
        });

      var formData = {};
      fields.forEach(function (name) {
        var element = elements[name];
        formData[name] = element.value;
        if (element.length) {
          var data = [];
          for (var i = 0; i < element.length; i++) {
            var item = element.item(i);
            if (item.checked || item.selected) {
              data.push(item.value);
            }
          }
          formData[name] = data.join(", ");
        }
      });

      // add form-specific values into the data
      formData.formDataNameOrder = JSON.stringify(fields);
      formData.formGoogleSheetName = form.dataset.sheet || "Sheet1"; // default sheet name
      formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

      console.log(formData);
      return formData;
    }

    function handleFormSubmit(event) {
      event.preventDefault();
      const form = event.target;
      const data = getFormData(form);

      if (data.email && !validEmail(data.email)) {
        var invalidEmail = form.querySelector(".email-invalid");
        if (invalidEmail) {
          invalidEmail.style.display = "block";
          return false;
        }
      } else {
        // disableAllButtons(form);
        setLoading(true); // Show loading animation
        var url = form.action;
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onreadystatechange = function () {
          // console.log(xhr.status, xhr.statusText);
          // console.log(xhr.responseText);
          if (xhr.readyState === 4) { // Check if the request is complete
            if (xhr.status === 200) {
              setSuccess(true); // Form sent successfully
              setLoading(false); // Hide loading animation
            } else {
              // Handle any error here
              setLoading(false); // Hide loading animation
            }
          }
        };
        var encoded = Object.keys(data).map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        }).join('&');
        xhr.send(encoded);
      }
    }

    function disableAllButtons(form) {
      var buttons = form.querySelectorAll("button");
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true;
      }
    }

    return (
      <form
        className="gform pure-form pure-form-stacked"
        onSubmit={handleFormSubmit}
        data-email="info@haganlubricants.com"
        action="https://script.google.com/macros/s/AKfycbzsEIV6Ir3Hb0lSY_dmaTZ2cHhDOq9mkZ0OwGRYHHF6xb-wnc2tsG8S-RJmjoqA8ei3mA/exec"
      >
        <div className="OrderForm">
          <div className="OrderLeftBlock">
            <input type="text" name="Name" placeholder="Name" value={selectedName} required onChange={handleNameChange}  ref={inputRef} />
            <input type="email" name="Email" placeholder="Email" value={selectedEmail} required onChange={handleEmailChange}  ref={emailRef} />
            <input type="number" name="Mobile" placeholder="Mobile Number" value={selectedNumber} onChange={handleNumberChange}  ref={numberRef} />
            <input
              name="Message"
              placeholder="What would you like to discuss"
              value={selectedMessage}
              onChange={handleMessageChange}  ref={messageRef}
            ></input>
          </div>
          <div className="OrderRightBlock">

          {/* SELECT CATEGORY */}

            <div class="select-wraper">
              <select
                id="input-category"
                class="select-category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                required
              >
                <option value="Not Select">select category</option>
                {category.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
              <span class="custom-arrow">▼</span>
            </div>

            {/* SELECT PRODUCT */}

            <div class="select-wraper">
              <select
                id="input-category"
                class="select-category"
                name="product"
                value={selectedProduct}
                onChange={handleProductChange}
              >
                <option value="Not Select">select product</option>
                {selectedCategory === "MCO" &&
                  mcoProduct.map((product) => (
                    <option key={product} value={product}>
                      {product}
                    </option>
                  ))}
                {selectedCategory === "PCMO" &&
                  pcmoProduct.map((product) => (
                    <option key={product} value={product}>
                      {product}
                    </option>
                  ))}
                {selectedCategory === "DIESEL" &&
                  dieselProduct.map((product) => (
                    <option key={product} value={product}>
                      {product}
                    </option>
                  ))}
                {selectedCategory === "GREASE" &&
                  greaseProduct.map((product) => (
                    <option key={product} value={product}>
                      {product}
                    </option>
                  ))}
                {selectedCategory === "ATF" &&
                  atfProduct.map((product) => (
                    <option key={product} value={product}>
                      {product}
                    </option>
                  ))}
              </select>
              <span class="custom-arrow">▼</span>
            </div>
            <div className="SizeFieds">
              <div class="select-wraper">
                <select
                  id="input-category"
                  class="select-category"
                  name="size"
                  value={selectedSize} onChange={handleSizeChange}  ref={sizeRef}
                >
                  <option value="Not Select">Select Size</option>
                  {productOptions[selectedProduct] &&
                    productOptions[selectedProduct].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
                <span class="custom-arrow">▼</span>
              </div>
              <input placeholder="total packs" type="number" name="quantity" value={selectedQuantity} onChange={handleQuatityChange}  ref={quatityRef} />
            </div> 

             <div class="select-wraper">
              <select
                id="input-category"
                class="select-category"
                value={selectedPayment} onChange={handlePaymentChange}  ref={paymentRef}
              >
                <option value="Not Select" > Payment Method</option>
                <option value="online">Online Payment</option>
                <option value="cod">Cash On Delivery</option>
              </select>
              <span class="custom-arrow">▼</span>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loading-animation">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="76"
              visible={true}
            />
          </div>
        ) : success ? (
          <div className="thankyou_message">
            <h1>Thanks for your order</h1>
            <div className="close-btn">
              <p className="CloseThankMessage" onClick={() => window.location.reload()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </p>
            </div>
          </div>
        ) : (
          <button className="submit press-effect">send</button>
        )}
      </form>
    );
  }

  return (
    <div className="TopParent">
    <Helmet>
        <title>Order Now</title>
      </Helmet>
      <Navbar LogoSrc={"/images/hagan_logo.svg"}  />
      <div className={`ProductPageParent ${isVisible ? "show" : ""}`}>
        {/* <Image Src={"/images/HomePage/HomePageBG.webp"} Width={"100%"} /> */}
        <div className="ContactUsParent">
          <div className="contactForm">
            {/* <h2>Contact Us</h2> */}
            <ContactForm />
            <div className="bottomText">
              <div className="officelocationAddress">
                <div className="OfficeAddressSec">
                  <Text
                    text="Hagan Lubricants Dubai"
                    Color="white"
                    FontSize="2rem"
                    Margin="25px 0px 15px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    Color="#B5B5B5"
                    Margin="25px 0px 15px 0px"
                    text="Momin Oil Industry FZCO P.O. Box: 61292. R/A # 7 Jebel Ali Free Zone. Dubai, UAE"
                  />
                  <Text
                    FontSize="1.6rem"
                    Color="#B5B5B5"
                    Margin="25px 0px 5px 0px"
                    text="P: 0097148819717"
                  />

                  <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com">
                    {" "}
                    E: info@moifzco.ae
                  </a>
                  <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com">
                    {" "}
                    E: info@haganlubricants.com
                  </a>
                 
                </div>
                <div className="OfficeAddressSec">
                  <Text
                    text="Hagan Lubricants Pakistan"
                    Color="white"
                    FontSize="2rem"
                    Margin="25px 0px 15px 0px"
                  />
                  <Text
                    FontSize="1.6rem"
                    Color="#B5B5B5"
                    Margin="25px 0px 15px 0px"
                    text="Siddique Trade Center, 7th Floor Office 724 Gulberg, Lahore, Pakistan"
                  />
                  <Text
                    FontSize="1.6rem"
                    Color="#B5B5B5"
                    Margin="25px 0px 5px 0px"
                    text="P: 042 3581 7214"
                  />

                  <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com">
                    {" "}
                    E: info@haganlubricants.com
                  </a>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
