import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./MotorCycleProductOutlet.css"

export default function MotorCycleProductOutlet() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add the "show" class after the component mounts to trigger the slide-in effect
    setIsVisible(true);
  }, []);


  return (
    <div className={`MotorCycleProductList  ${isVisible ? "show" : ""}`}  >
      <Outlet />

    </div>
  );
}
