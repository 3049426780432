import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function G_8_80w90() {
  const ProductProvides = [
    "Provides good gear life.",
    "Its extreme pressure properties protect hypoid and other types of gears from scuffing and wear.",
    "It gives rust and corrosion protection.",
    "It has good foam inhibition.",
    "It has market general performance.",
    "It has thermal and oxidation stability that allows for appropriate gear protection in non-critical operations.",
    "Provides seal protection by giving protection against oil seal deterioration.",
  ];
  return (
    <div className="Pao-0w20Parent d-flex">
      <div className="ProductDetails" style={{ width: "40%" }}>
      <div className="ProductDetailsInner">
        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/gear-series"
        />
        <div className="ProductSpecificatons">
          <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - G-80" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="80W90 - GL-4" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="Hagan 80W90 It is a high performing, multi-grade manual transmission fluid (MTF) best suited for Asian passenger cars manual transmissions and trans axels. It is also recommended for use in other manual transmission fitted in modern and light commercial vehicles. It is made from paraffinic base stocks and has a carefully balanced additive package to give acceptable market general gear protection and lubricant life."
          />
          <Text
            FontSize={"1.8rem"}
            text="It is not recommended for use in heavy-duty truck and bus manual transmissions, or where API GL-4 or GL-5 type gear lubricants are specified."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="HAGAN - G-80 80W90 Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
          />
          <ul>
            {ProductProvides.map((item) => (
              <li>
                <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
      <div className="ProductImage">
        <Image Src={"/images/GearProduct/Gear-G-80-80W90.png"} />
      </div>
    </div>
  );
}
