import React from "react";
import Button from "../Button/Button";
import "./MineralsProductList.css"

export default function MineralsProductList() {
  return (
    <div className="MineralsProductList">
    <div className="Product_List">
      <Button    ImgWidth={"100%"} Src={"/images/Minerals/Mineral HP-1.png"} RouteLink={"/product/minerals/hp-1-20w50"}  />
      <Button  ImgWidth={"100%"}  Src={"/images/Minerals/Mineral HP-40.png"} RouteLink={"/product/minerals/hp-40-10w40"}   />
      <Button   ImgWidth={"100%"} Src={"/images/Minerals/Mineral-MS-1.png"} RouteLink={"/product/minerals/ms-1-20w50"}  />
    </div>
      
    </div>
      
  );
}
