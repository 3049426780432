import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Atf_Uh_6() {
  const ProductProvides = [
    "Has extended fluid life",
    "Helps in improved oxidation and thermal stability",
    "Has optimized frictional characteristics that help in smooth gear shifting during low temperature operation and helps in prevention against transmission vibration",
    "Has excellent resistance against sludge and deposit formation",
    "Has enhanced anti-wear protection that helps to contribute towards extended transmission life",
    "Has very good low temperature performance",
  ];
  return (
    <div className="Pao-0w20Parent d-flex justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
          <Button
            BGcolor={"white"}
            Radius={"50%"}
            Width={"50px"}
            Height={"50px"}
            text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
            RouteLink="/product/transmission_series"
          />
          <div className="ProductSpecificatons">
            <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
            <Text text="HAGAN - UH-6" FontSize={"4rem"} FontWeight={"600"} />
            <Text text="ATF - DEXRON-VI" />
            <Text
              Margin={"25px 0px 20px 0px"}
              FontSize={"1.8rem"}
              text="HAGAN ATF Dexron VI is a high-performing synthetic formulation that fulfills the strict requirements of new GM 6-speed transmissions as well as earlier transmissions that required Dexron III (H), Dexron -III (G), Dexron-II (E) andprior Dexron automatic transmission fluids."
            />
          </div>
          <div className="ProductProvides">
            <Text
              text="HAGAN - UH-6 ATF-DEXRON-VI Provides"
              Margin={"25px 0px 25px 0px"}
              FontSize={"2.5rem"}
            />
            <ul>
              {ProductProvides.map((item) => (
                <li>
                  <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="ProductImage">
        <Image Src={"/images/TransmissionProduct/ATF-UH-6.png"} Width={"55%"} />
      </div>
    </div>
  );
}
