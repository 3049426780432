import React, { useEffect, useState } from "react";
import "./PoaSeriesProductList.css";
import Text from "../Text/Text";
import Image from "../Image/Image";

export default function PoaSeriesProductList() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add the "show" class after the component mounts to trigger the slide-in effect
    setIsVisible(true);
  }, []);

  const ProductProvides = [
    "It has excellent thermal and oxidation stability",
    "Good low temperature capabilities",
    "Active cleaning agent",
    "Provides brilliant wear protection",
    "Cleans up accumulated sludge",
    "Protects engine up to 7000km",
  ];

  return (
    <div className={`PoaSeriesProductList  ${isVisible ? "show" : ""}`}>
      {/* <Button Src={"/images/PaoSeriesPage/0w-20Pao.webp"} RouteLink={"0w20-pao"}  /> */}
      <div className="Pao-0w20Parent d-flex">
        <div className="ProductDetails">
          <div className="ProductDetailsInner">
            {/* <Button
          
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/pao_series"

        /> */}
            <div className="ProductSpecificatons">
              <Text text="Pao Series" Margin={"25px 0px 0px 0px"} />
              <Text text="HAGAN - GT-1" FontSize={"4rem"} FontWeight={"600"} />
              <Text text="0W20 - SP - PAO" />
              <Text
                Margin={"25px 0px 20px 0px"}
                FontSize={"1.8rem"}
                text="Hagan 0W-20 SP is premium synthetic oil that can help in protecting engine parts from problems related to lubricant failures for up to 7,000 km or up to your vehicle’s oil change interval, whichever takes more time and gives long life to the engine. It gives excellent engine protection and more efficiency in fuel economy to keep your engine parts new. Hagan 0W-20 surpasses the requirements of toughest industry standards and performs in the best manner."
              />
              <Text
                FontSize={"1.8rem"}
                text="It is developed with a mixture of extreme performing synthetic base stocks strengthened with a balanced component additive system. It’s low viscosity and synthetic formula facilitates in increased engine efficiency and better fuel economy while giving excellent protection of engine. It is suitable for cars where this viscosity is recommended and for cold conditions for helping inquick starts and lubrication. It is not suitable for 2-Cycle or aviation engines."
              />
            </div>
            <div className="ProductProvides">
              <Text
                text="Pao Series Provides"
                Margin={"25px 0px 25px 0px"}
                FontSize={"2.5rem"}
              />
              <ul>
                {ProductProvides.map((item) => (
                  <li>
                    <Text text={item} FontSize={"1.6rem"} Color={"#949494"} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="ProductImage">
          <Image Src={"/images/PaoSeriesPage/0w-20Pao.png"} Width={"52%"} />
        </div>
      </div>
    </div>
  );
}
