import React, { useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import "./HomePage.css"
import HomeSlider from '../../Components/Slider/Slider'
import { Helmet } from 'react-helmet';

export default function HomePage() {

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };
 
  const getLogoSrc = () => {
    // Use the current slide index to determine the appropriate logo source

    if (currentSlide === 1)
    {
      return "/images/logo_dark.svg"
    }
    else
    {
      return  "/images/hagan_logo.svg"  

    }
  };

  const getNavbarFontColor = () => {
    if (currentSlide === 1 )
    {
      return  "black"; // Change to the desired color
    }
    else 
    return "white"
  };



  return (
    <div className='HomePageParent'>
    <Helmet>
        <title>HAGAN</title>
      </Helmet>
      <Navbar  LogoSrc={getLogoSrc()} FontColor={getNavbarFontColor()}  />
      <HomeSlider onSlideChange={handleSlideChange} currentSlide={currentSlide}/>
    </div>
  )
}
