import React from "react";
import Button from "../Button/Button";
import "./SemiSyntheticProductList.css";

export default function SemiSyntheticProductList() {
  return (
    <>
      <div className="Product_List">
        <Button
          ImgWidth={"100%"}
          Src={"/images/SemiSynthetic/GT-5-5W30.png"}
          RouteLink={"/product/semi-synthetic-series/5w30-gt-5"}
        />
        <Button
          ImgWidth={"100%"}
          Src={"/images/SemiSynthetic/GT-6-10W40.png"}
          RouteLink={"/product/semi-synthetic-series/5w40-gt-6"}
        />
        <Button
          ImgWidth={"100%"}
          Src={"/images/SemiSynthetic/HP-N-20W50.png"}
          RouteLink={"/product/semi-synthetic-series/5w50-hp-n"}
        />
      </div>
    </>
  );
}
