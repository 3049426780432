import React from "react";
import Button from "../Button/Button";
import "./TransmissionProductList.css";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

export default function TransmissionProductList() {
  const SwiperCarousel = () => {
    return (
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={25}
        slidesPerView={2.4}
        navigation
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1.2, // Number of slides to show on mobile
            spaceBetween: 5, // Space between slides on mobile
          },
          820: {
            slidesPerView: 3, // Number of slides to show on mobile
            spaceBetween: 5, // Space between slides on mobile
          },
        }}
        pagination={{
          type: "bullets",
          spaceBetween: 20,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <Button
            ImgWidth={"100%"}
            Src={"/images/TransmissionProduct/ATF-CV-95.png"}
            RouteLink={"/product/transmission_series/atf_cv_95"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Button
            ImgWidth={"100%"}
            Src={"/images/TransmissionProduct/ATF-UHM.png"}
            RouteLink={"/product/transmission_series/atf_uhm"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Button
            ImgWidth={"100%"}
            Src={"/images/TransmissionProduct/ATF-UH-6.png"}
            RouteLink={"/product/transmission_series/atf_uh_6"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Button
            ImgWidth={"100%"}
            Src={"/images/TransmissionProduct/ATF-UH-3.png"}
            RouteLink={"/product/transmission_series/atf_uh_3"}
          />
        </SwiperSlide>
        <style>
          {`.swiper-pagination{
            color: red;
          },
          .swiper-pagination-bullet {
            color: red;
            background-color: red;
          }
          .swiper{
            height: 60vh;
          }`}
        </style>
      </Swiper>
    );
  };

  return (
    <div
      className="TransmissionProductListSwipper"
      style={{ display: "contents" }}
    >
      <SwiperCarousel />

      <div className="Product_List">
        <Button
          ImgWidth={"95%"}
          Src={"/images/TransmissionProduct/ATF-CV-95.png"}
          RouteLink={"/product/transmission_series/atf_cv_95"}
        />
        <Button
          ImgWidth={"95%"}
          Src={"/images/TransmissionProduct/ATF-UHM.png"}
          RouteLink={"/product/transmission_series/atf_uhm"}
        />
        <Button
          ImgWidth={"95%"}
          Src={"/images/TransmissionProduct/ATF-UH-6.png"}
          RouteLink={"/product/transmission_series/atf_uh_6"}
        />
        <Button
          ImgWidth={"95%"}
          Src={"/images/TransmissionProduct/ATF-UH-3.png"}
          RouteLink={"/product/transmission_series/atf_uh_3"}
        />
      </div>
    </div>
  );
}
