import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";
import "./GT-5-5w30.css";

export default function GT_5_5w30() {
  const ProductProvides = [
    "It retains engine’s strength and provides protection",
    "The proven anti-wear additive system protects the engine under all operating conditions",
    "It provides brilliant wear protection even in most complex valve train mechanism",
    "Low viscosity provides less internal engine friction",
    "Enhanced corrosion protection",
    "Protects engine from deposits that lead to lower performance",
    "Good thermal stability and oxidation resistance facilitates in premium protection against oil degradation and prevents sludge formation",
  ];
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails" style={{ width: "40%" }}>
        <div className="ProductDetailsInner">
          <Button
            BGcolor={"white"}
            Radius={"50%"}
            Width={"50px"}
            Height={"50px"}
            text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
            RouteLink="/product/semi-synthetic-series"
          />
          <div className="ProductSpecificatons">
            <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
            <Text text="HAGAN - GT-5" FontSize={"4rem"} FontWeight={"600"} />
            <Text text="5W30 - SP" />
            <Text
              Margin={"25px 0px 20px 0px"}
              FontSize={"1.8rem"}
              text="Hagan 5W-30 Synthetic motor oil is a next generation of oil developed for the cleanliness of engines which also helps in more effective fuel economy while reducing emissions as well."
            />
            <Text
              FontSize={"1.8rem"}
              text="It is a resource conserving gasoline engine oil made from synthetic base oils for usage in passenger cars and light truck engines requiring low viscosity, turbocharged gasoline direct injection engines and small displacement turbocharged engines that need LSPI (Low Speed Pre Ignition) protection."
            />
          </div>
          <div className="ProductProvides">
            <Text
              text="Pao Seires Provides"
              Margin={"25px 0px 25px 0px"}
              FontSize={"2.5rem"}
            />
            <ul>
              {ProductProvides.map((item) => (
                <li>
                  <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="ProductImage">
        <Image Src={"/images/SemiSynthetic/GT-5-5W30.png"} Width={"55%"} />
      </div>
    </div>
  );
}
