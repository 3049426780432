import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage/HomePage";
import ProductPage from "./Pages/ProductsPage/ProductPage";
import SemiSyntheticProductList from "./Components/SemiSyntheticProductList/SemiSyntheticProductList";
import MineralsProductList from "./Components/MineralsProductList/MineralsProductList";
import ProductPageContent from "./Components/ProductPageContent/ProductPageContent";
import SemiSyntheticProductOutlet from "./Pages/SemiSyntheticProductOutlet/SemiSyntheticProductOutlet";
import MineralsProductOutlet from "./Pages/MineralsProductOutlet/MineralsProductOutlet";
import DieselProductList from "./Components/DieselProductList/DieselProductList";
import Diesel_HP_7_20W50 from "./Components/AllProducts/DieselProductSeries/Diesel-HP-7-20W50/Diesel-HP-7-20W50";
import HP_1_20w50 from "./Components/AllProducts/GasolineMinerals/HP-1-20w50/HP-1-20w50";
import MS_1_20w50 from "./Components/AllProducts/GasolineMinerals/MS-1-20w50/MS-1-20w50";
import HP_40_10w40 from "./Components/AllProducts/GasolineMinerals/HP-40-10w40/HP-40-10w40";
import HP_N_20w50 from "./Components/AllProducts/GasolineSyntheticSeries/HP-N-20w50/HP-N-20w50";
import GT_6_5w40 from "./Components/AllProducts/GasolineSyntheticSeries/GT-6-5w40/GT-6-5w40";
import GT_5_5w30 from "./Components/AllProducts/GasolineSyntheticSeries/GT-5-5w30/GT-5-5w30";
import PoaSeriesProductList from "./Components/PoaSeriesProductList/PoaSeriesProductList";
import Diesel_HP_9_15W40 from "./Components/AllProducts/DieselProductSeries/Diesel-HP-9-15W40/Diesel-HP-9-15W40";
import Diesel_HP_60_20W60 from "./Components/AllProducts/DieselProductSeries/Diesel-HP-60-20W60/Diesel-HP-60-20W60";
import Diesel_MS_9_SAE50 from "./Components/AllProducts/DieselProductSeries/Diesel-MS-9-SAE50/Diesel-MS-9-SAE50";
import Diesel_HYD_68 from "./Components/AllProducts/DieselProductSeries/Diesel-HYD-68/Diesel-HYD-68";
import DieselProductOutlet from "./Pages/DieselProductOutlet/DieselProductOutlet";
import MotorCycleProductOutlet from "./Pages/MotorCycleProductOutlet/MotorCycleProductOutlet";
import Bike_4T_J2_20W50 from "./Components/AllProducts/MotorCycleProductSeries/4T-J2-20W50/4T-J2-20W50";
import Bike_4T_J_20W50 from "./Components/AllProducts/MotorCycleProductSeries/4T-J-20W50/4T-J-20W50";
import GearProductOutlet from "./Pages/GearProductOutlet/GearProductOutlet";
import G_8_80w90 from "./Components/AllProducts/GearProductSeries/G-8-80w90/G-8-80w90";
import G_85_85w140 from "./Components/AllProducts/GearProductSeries/G-85-85w140/G-85-85w140";
import G_140 from "./Components/AllProducts/GearProductSeries/G-140/G-140";
import MotorCycleProductList from "./Components/MotorCycleProductList/MotorCycleProductList";
import GearProductList from "./Components/GearProductList/GearProductList";
import TransmissionProductOutlet from "./Pages/TransmissionProductOutlet/TransmissionProductOutlet";
import TransmissionProductList from "./Components/TransmissionProductList/TransmissionProductList";
import CV_95 from "./Components/AllProducts/TransmissionProductSeries/CV-95/CV-95";
import Atf_Multi from "./Components/AllProducts/TransmissionProductSeries/Atf_Multi/Atf_Multi";
import Atf_Uh_6 from "./Components/AllProducts/TransmissionProductSeries/Atf_Uh_6/Atf_Uh_6";
import Atf_Uh_3 from "./Components/AllProducts/TransmissionProductSeries/Atf_Uh_3/Atf_Uh_3";
import ContactPage from "./Pages/ContactPage/ContactPage";
import OrderNow from "./Pages/OrderNowPage/OrderNow";
import ScrollToTop from "./Components/ScrolToTop/ScrolToTop";
import DieselHydProductList from "./Components/DieselHydProductList/DieselHydProductList";
import { useEffect } from "react";

function App() {

  // ON CLICK MOUSE POINTER ANIMATION
  const pulse = (event) => {
    const circle = document.createElement('div');
    circle.setAttribute('class', 'circle');
    document.body.appendChild(circle);
    circle.style.top = event.pageY + 'px';
    circle.style.left = event.pageX + 'px';
    setTimeout(() => {
      document.body.removeChild(circle);
    }, 500);
  };

  useEffect(() => {
    document.addEventListener('click', pulse);

    return () => {
      document.removeEventListener('click', pulse);
    };
  }, []);
    // ON CLICK MOUSE POINTER ANIMATION END



  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/product" element={<ProductPage />}>
            <Route index element={<ProductPageContent />} />

            {/* PAO SERIES */}
            <Route
              path="/product/pao_series"
              element={<PoaSeriesProductList />}
            />

            {/* SEMI SYNTHETIC SERIES */}

            <Route
              path="/product/semi-synthetic-series"
              element={<SemiSyntheticProductOutlet />}
            >
              <Route index element={<SemiSyntheticProductList />} />
              <Route
                path="/product/semi-synthetic-series/5w30-gt-5"
                element={<GT_5_5w30 />}
              />
              <Route
                path="/product/semi-synthetic-series/5w40-gt-6"
                element={<GT_6_5w40 />}
              />
              <Route
                path="/product/semi-synthetic-series/5w50-hp-n"
                element={<HP_N_20w50 />}
              />
            </Route>

            {/* MINERALS SERIES */}

            <Route path="/product/minerals" element={<MineralsProductOutlet />}>
              <Route index element={<MineralsProductList />}></Route>
              <Route
                path="/product/minerals/hp-1-20w50"
                element={<HP_1_20w50 />}
              />
              <Route
                path="/product/minerals/hp-40-10w40"
                element={<HP_40_10w40 />}
              />
              <Route
                path="/product/minerals/ms-1-20w50"
                element={<MS_1_20w50 />}
              />
            </Route>

            {/* DISEL ENGIN OIL SERIES */}
            <Route
              path="/product/diesel_series"
              element={<DieselProductOutlet />}
            >
              <Route index element={<DieselProductList />} />

              <Route
                path="/product/diesel_series/hp-7-20w50"
                element={<Diesel_HP_7_20W50 />}
              />
              <Route
                path="/product/diesel_series/hp-9-15w40"
                element={<Diesel_HP_9_15W40 />}
              />
              <Route
                path="/product/diesel_series/hp-60-20w60"
                element={<Diesel_HP_60_20W60 />}
              />
              <Route
                path="/product/diesel_series/ms-9-sae50"
                element={<Diesel_MS_9_SAE50 />}
              />
            </Route>

            {/* DISEL ENGIN Hydraulic SERIES */}
            <Route
              path="/product/diesel_series/hyd-68"
              element={<DieselHydProductList />}
            />

            {/* MotorCycle OIL SERIES */}
            <Route
              path="/product/motorcycle_series"
              element={<MotorCycleProductOutlet />}
            >
              <Route index element={<MotorCycleProductList />} />
              <Route
                path="/product/motorcycle_series/4t_j_20w50"
                element={<Bike_4T_J_20W50 />}
              />
              <Route
                path="/product/motorcycle_series/4t_j2_20w50"
                element={<Bike_4T_J2_20W50 />}
              />
            </Route>

            {/* Gear SERIES */}

            <Route path="/product/gear-series" element={<GearProductOutlet />}>
              <Route index element={<GearProductList />} />
              <Route
                path="/product/gear-series/g-80-80w90"
                element={<G_8_80w90 />}
              />
              <Route
                path="/product/gear-series/g-85-85w140"
                element={<G_85_85w140 />}
              />
              <Route path="/product/gear-series/g_140" element={<G_140 />} />
            </Route>

            {/* TRANSMISSION SERIES */}

            <Route
              path="/product/transmission_series"
              element={<TransmissionProductOutlet />}
            >
              <Route index element={<TransmissionProductList />} />
              <Route
                path="/product/transmission_series/atf_cv_95"
                element={<CV_95 />}
              />
              <Route
                path="/product/transmission_series/atf_uhm"
                element={<Atf_Multi />}
              />
              <Route
                path="/product/transmission_series/atf_uh_6"
                element={<Atf_Uh_6 />}
              />
              <Route
                path="/product/transmission_series/atf_uh_3"
                element={<Atf_Uh_3 />}
              />
            </Route>
          </Route>

          {/* CONTACT US */}
          <Route path="/contact_us" element={<ContactPage />} />

          {/* ORDER NOW */}
          <Route path="/order_now" element={<OrderNow />} />

          

          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
