import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./DieselProductOutlet.css"

export default function DieselProductOutlet() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add the "show" class after the component mounts to trigger the slide-in effect
    setIsVisible(true);
  }, []);


  return (
    <div className={`DieselProductList ${isVisible ? "show" : ""}`} >
      <Outlet />

    </div>
  );
}
