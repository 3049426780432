import React from "react";
import "./MotorCycleProductList.css"
import Button from "../Button/Button";

export default function MotorCycleProductList() {

  return (
    <>
    <div className="Product_List">
    <Button  ImgWidth={"100%"} Src={"/images/MotorCycleProduct/bike-20W50-SJ.png"} RouteLink={"/product/motorcycle_series/4t_j_20w50"}  />
      <Button ImgWidth={"100%"} Src={"/images/MotorCycleProduct/bike-20W50-SJ-22.png"} RouteLink={"/product/motorcycle_series/4t_j2_20w50"}  />
    </div>
      

    </>
      
      
  );
}
