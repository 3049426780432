import React from "react";
import Button from "../Button/Button";
import "./DieselProductList.css"

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

export default function DieselProductList() {

  const SwiperCarousel = () => {



    return (
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={25}
        slidesPerView={2.4}
        navigation
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1.2, // Number of slides to show on mobile
            spaceBetween: 5, // Space between slides on mobile
          },
          820: {
            slidesPerView: 3, // Number of slides to show on mobile
            spaceBetween: 5, // Space between slides on mobile
          }

        }}

        pagination={{
          type: "bullets",
          spaceBetween: 20,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
        <Button ImgWidth={"100%"} Src={"/images/DieselProduct/heavy-HP-7-20W50.png"} RouteLink={"/product/diesel_series/hp-7-20w50"}  />
        </SwiperSlide>
        <SwiperSlide>
        <Button ImgWidth={"100%"} Src={"/images/DieselProduct/heavy-HP-9-15W40.png"} RouteLink={"/product/diesel_series/hp-9-15w40"}  />
        </SwiperSlide>
        <SwiperSlide>
        <Button ImgWidth={"100%"} Src={"/images/DieselProduct/heavy-HP-60-20W60.png"} RouteLink={"/product/diesel_series/hp-60-20w60"}  />
        </SwiperSlide>
        <SwiperSlide>
        <Button ImgWidth={"100%"} Src={"/images/DieselProduct/MS-9.png"} RouteLink={"/product/diesel_series/ms-9-sae50"}  />
        </SwiperSlide>
        <style>
          {`.swiper-pagination{
            color: red;
          },
          .swiper-pagination-bullet {
            color: red;
            background-color: red;
          }
          .swiper{
            height: 60vh;
          }`}
        </style>
      </Swiper>
    );
  };


  return (
    <>
    <div className="DieselProductListSwipper" style={{display:"contents"}}>
    {/* <div className="Product_List"> */}
    <SwiperCarousel />
    {/* </div> */}

  {/* FOR MOBILE VERSION */}
    <div className="Product_List">
        <Button ImgWidth={"85%"} Src={"/images/DieselProduct/heavy-HP-7-20W50.png"} RouteLink={"/product/diesel_series/hp-7-20w50"}  />
        <Button ImgWidth={"85%"} Src={"/images/DieselProduct/heavy-HP-9-15W40.png"} RouteLink={"/product/diesel_series/hp-9-15w40"}  />
        <Button ImgWidth={"85%"} Src={"/images/DieselProduct/heavy-HP-60-20W60.png"} RouteLink={"/product/diesel_series/hp-60-20w60"}  />
        <Button ImgWidth={"85%"} Src={"/images/DieselProduct/MS-9.png"} RouteLink={"/product/diesel_series/ms-9-sae50"}  />
    </div>
    
    </div>
    </>
      
      
  );
}
