import React, { useState } from "react";
import Button from "../Button/Button";
import "./SideNav.css";

export default function SideNav() {
  // Define a state variable to track visibility
  const [GasolineEngineOilVisible, setGasolineEngineOilVisible] =
    useState(null);
  const [GasolineTabReverse, setGasolineTabReverse] = useState(false);

  // Function to handle the button click and toggle visibility
  const ClickGasoline = () => {
    setGasolineEngineOilVisible(!GasolineEngineOilVisible);
    setDieselEngineOilVisible(false);
    if (GasolineEngineOilVisible) {
      setGasolineTabReverse(true);
    } else {
      setGasolineTabReverse(false);
    }
  };

  // Define a state variable to track visibility DieselTabReverse
  const [DieselEngineOilVisible, setDieselEngineOilVisible] = useState(null);
  const [DieselTabReverse, setDieselTabReverse] = useState(false);

  // Function to handle the button click and toggle visibility
  const ClickDiesel = () => {
    setDieselEngineOilVisible(!DieselEngineOilVisible);
    setGasolineEngineOilVisible(false);
    if (DieselEngineOilVisible) {
      setDieselTabReverse(true);
    } else {
      setDieselTabReverse(false);
    }
  };

  return (
    <div className="ProductPageSideNav">
      {/* GASOLINE ENGINE OIL TAB */}
      <div className="ProductSideNavLink1">
        <div className="GasolineEngineOilTab">
        <div class="TabsSvg">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
            </svg>
              </div>
          <Button
            text="gasonline Engine oil"
            FontSize="1.8rem"
            FontWeight="400"
            handleClick={ClickGasoline}
            TextTransform={"uppercase"}
          />
        </div>
        <div
          className={`GasolineEngineOil ${
            GasolineEngineOilVisible ? "visible" : ""
          } ${GasolineTabReverse ? "reverse" : ""}`}
        >
          <div className="GasolineEngineOil1">
            <div class="TabsSvg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
              </svg>
            </div>

            <Button
              RouteLink={"/product/pao_series"}
              text="PAO Series"
              FontSize="1.8rem"
              FontWeight="400"
              Color="#797979"
            />
          </div>
          <div className="GasolineEngineOil2">
            <div class="TabsSvg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
              </svg>
            </div>
            <Button
              RouteLink={"/product/semi-synthetic-series"}
              text=" Synthetic / Semi Synthetic Series"
              FontSize="1.8rem"
              FontWeight="400"
              Color="#797979"
            />
          </div>

          <div className="GasolineEngineOil2">
              <div class="TabsSvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                </svg>
              </div>
            <Button
              RouteLink="/product/minerals"
              text="Minerals / Premium Mineral "
              FontSize="1.8rem"
              FontWeight="400"
              Color="#797979"
            />
          </div>
        </div>
      </div>

      <div className="ShowOnClick">
        {/* DIESEL ENGINE OIL TAB */}
        <div className="ProductSideNavLink1">
          <div className="GasolineEngineOilTab">
            <div class="TabsSvg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
              </svg>
            </div>

            <Button
              text="DIESEL Engine oil"
              FontSize="1.8rem"
              FontWeight="400"
              handleClick={ClickDiesel}
              TextTransform={"uppercase"}
            />
          </div>

          <div
            className={`DieselEngineOil ${
              DieselEngineOilVisible ? "visible" : ""
            } ${DieselTabReverse ? "reverse" : ""}`}
          >
            <div className="GasolineEngineOil1">
              <div class="TabsSvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </div>

              <Button
                RouteLink={"/product/diesel_series"}
                text="HP Series"
                FontSize="1.8rem"
                FontWeight="400"
                Color="#797979"
              />
            </div>
            <div className="GasolineEngineOil2">
              <div class="TabsSvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </div>
              <Button
                RouteLink={"/product/diesel_series/hyd-68"}
                text="Hydraulic Oil"
                FontSize="1.8rem"
                FontWeight="400"
                Color="#797979"
              />
            </div>
          </div>
        </div>
        <div className="ShowOnClick">
          {/* MOTOR CYCLE ENGINE OIL TAB */}
          <div className="ProductSideNavLink2">
            <div className="DieselEngineOilTab">
              <div class="TabsSvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </div>{" "}
              <Button
                RouteLink="/product/motorcycle_series"
                text="motor cycle engine oil"
                FontSize="1.8rem"
                FontWeight="100"
                TextTransform={"uppercase"}
              />
            </div>
          </div>
          {/* GEAR OIL TAB */}
          <div className="ProductSideNavLink2">
            <div className="DieselEngineOilTab">
              <div class="TabsSvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </div>{" "}
              <Button
                RouteLink="/product/gear-series"
                text="gear oil"
                FontSize="1.8rem"
                FontWeight="100"
                TextTransform={"uppercase"}
              />
            </div>
          </div>
          {/* TRANSMISSION OIL TAB */}
          <div className="ProductSideNavLink2">
            <div className="DieselEngineOilTab">
              <div class="TabsSvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </div>
              <Button
                RouteLink="/product/transmission_series"
                text="Transmission Engine oil"
                FontSize="1.8rem"
                FontWeight="100"
                TextTransform={"uppercase"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
