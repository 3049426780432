import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function MS_1_20w50() {

    const ProductProvides = [
        "Higher anti-oxidation properties",
        "Higher anti-rust performance",
        "Prevents sludge formation and protect engine",
        "Excellent performance in high temperatures",
        "Brilliant protection against wear",
        "Reduced oil consumption saves on maintenance"
    ]
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/minerals"

        />
        <div className="ProductSpecificatons">
        <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - MS-1" FontSize={"4rem"} FontWeight={"600"}   />
          <Text text="20W50 - SG/CG"  />
          <Text Margin={"25px 0px 20px 0px"} FontSize={"1.8rem"}  text="HAGAN 20W-50 SG/CD is a premium quality engine oil made from stellar quality mineral base stocks and additives which is suitable for older passenger and light duty commercial vehicle engines. It provides longer engine life and retains performance and strength of engine which results in less maintenance requirement." />
          <Text FontSize={"1.8rem"} text="It is used in light duty vehicles and older passenger cars in which API SG or lower graded lubricants are required and also where SAE 20W-50 viscosity is acceptable. It is also recommended for petrol engines that require SG, SF and SE grade oil or diesel engines that need CD or CC grade oils." />
        </div>
        <div className="ProductProvides">
        <Text text="HAGAN - MS-1 20W-50 SG/CD Seires Provides" Margin={"25px 0px 25px 0px"} FontSize={"2.5rem"} />
        <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
      <Image Src={"/images/Minerals/Mineral-MS-1.png"} Width={"65%"}   />
      </div>
    </div>
  );
}
