import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Atf_Uh_3() {
  const ProductProvides = [
    "It provides brilliant protection through additives against rust, corrosion and wear of transmission",
    "It defends against 3 causes of transmission failure which are heat, deposits and wear",
    "Its low temperature capability helps in smooth shifting of performance",
    "High viscosity index",
    "Low pour point",
  ];
  return (
    <div className="Pao-0w20Parent d-flex justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
          <Button
            BGcolor={"white"}
            Radius={"50%"}
            Width={"50px"}
            Height={"50px"}
            text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
            RouteLink="/product/transmission_series"
          />
          <div className="ProductSpecificatons">
            <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
            <Text text="HAGAN - UH-3" FontSize={"4rem"} FontWeight={"600"} />
            <Text text="ATF - DEXRON-III" />
            <Text
              Margin={"25px 0px 20px 0px"}
              FontSize={"1.8rem"}
              text="ATF Dexron-III is a high-end automatic transmission fluid formed to fulfill the requirements of this era’s more advanced transmissions that are performing at high functioning temperatures including electronic control. It is suitable for use in all automatic transmissions and hydraulic systems where Dexron-III oil recommended by the vehicle manufacturer. It comes with additives and rust, corrosion and oxidation agents to provide protection against wear of transmission parts."
            />
          </div>
          <div className="ProductProvides">
            <Text
              text="HAGAN - UH-3 ATF-DEXRON-III Provides"
              Margin={"25px 0px 25px 0px"}
              FontSize={"2.5rem"}
            />
            <ul>
              {ProductProvides.map((item) => (
                <li>
                  <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="ProductImage">
        <Image Src={"/images/TransmissionProduct/ATF-UH-3.png"} Width={"55%"} />
      </div>
    </div>
  );
}
