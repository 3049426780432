import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";


export default function HP_N_20w50() {
  const ProductProvides = [
    "Provides excellent engine protection",
    "Helps in smooth performance by providing engine cleanliness",
    "Helps in reducing engine wear",
    "Provides resistance against corrosion",
    "Has excellent thermal and oxidation stability",
    "Helps in engine start-up in cold weather",
    "Reduces maintenance cost by helping in less oil consumption and thickening"
  ];
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/semi-synthetic-series"
        />
        <div className="ProductSpecificatons">
          <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - HP-N" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="20W50 - SN" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="HAGAN 20W-50 - SN is overall synthetic multi-grade engine oil optimized to yield splendid engine protection and performance for the engines performing under utmost and challenging driving scenarios. It is formed from synthetic base stocks and modern preservative systems to give thermal and oxidation strength, making sure that there is minimal oil thickening at maximum performing temperatures and it controls deposits and ensures minimum engine wear."
          />
          <Text
            FontSize={"1.8rem"}
            text="It surpasses the tough industry standards of API SN/SL/SJ and performs better than conventional oil. It is formulated to use in gas fueled engines (natural gas and LPG) that are found in passenger cars and light commercial transport vehicles. Other than that it is applicable in engines in which API SN grade oil is required."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="Pao Seires Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
          />
          <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
        <Image Src={"/images/SemiSynthetic/HP-N-20W50.png"} Width={"55%"} />
      </div>
    </div>
  );
}
