import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function CV_95() {
  const ProductProvides = [
    "Superior Wet-Clutch Performance",
    "Excellent oxidation resistance and thermal stability for long fluid life",
    "Protects against sludge and varnish formation",
    "Protects against wear",
    "Protects against rust and corrosion",
    "Excellent low-temperature properties for easier shifting in cold weather",
    "Good seal compatibility",
    "Helps prevent belt and chain slipping and help extend transmission life",
    "Excellent metal-metal friction protection",
  ];
  return (
    <div className="Pao-0w20Parent d-flex justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
          <Button
            BGcolor={"white"}
            Radius={"50%"}
            Width={"50px"}
            Height={"50px"}
            text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
            RouteLink="/product/transmission_series"
          />
          <div className="ProductSpecificatons">
            <Text text="Transmission Seires" Margin={"25px 0px 0px 0px"} />
            <Text text="HAGAN - CV-95" FontSize={"4rem"} FontWeight={"600"} />
            <Text text="CVTF - MULTI" />
            <Text
              Margin={"25px 0px 20px 0px"}
              FontSize={"1.8rem"}
              text="Hagan Synthetic CVTF (Continuously Variable Transmission Fluid) is a premium quality fully synthetic transmission fluid specially designed for use in passenger cars with belt-driven continuously variable transmissions. It is formulated with carefully selected synthetic base fluids and technologically proven additives to meet the transmission requirements of both European and Japanese CVT manufacturers. This product may not be recommended in some hybrid vehicles requiring special fluids."
            />
            <Text
              FontSize={"1.8rem"}
              text="Hagan Synthetic CVTF has carefully balanced frictional properties to protect against belt- slippage and wear while also providing excellent anti-shudder performance. It has high shear-stability and excellent oxidation resistance for long service life. It helps protect against sludge and varnish formation, and has excellent low-temperature properties for easier shifting in cold weather. The synthetic formulation provides enhanced performance benefits at extreme temperatures compared with conventional, all-mineral transmission fluids."
            />
          </div>
          <div className="ProductProvides">
            <Text
              text="HAGAN - CV-95 CVTF-MULTI Provides"
              Margin={"25px 0px 25px 0px"}
              FontSize={"2.5rem"}
            />
            <ul>
              {ProductProvides.map((item) => (
                <li>
                  <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="ProductImage">
        <Image
          Src={"/images/TransmissionProduct/ATF-CV-95.png"}
          Width={"55%"}
        />
      </div>
    </div>
  );
}
