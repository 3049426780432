import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Diesel_HP_60_20W60() {

    const ProductProvides = [
        "Provides excellent wear protection at start up",
        "Provides good thermal stability and resistance to oxidation",
        "Prevents formation of deposits and sludge",
        "Prevents formation of deposits and sludge",
        "Provides excellent protection against rust, corrosion and wear",
    ]
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/diesel_series"

        />
        <div className="ProductSpecificatons">
          <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - HP-60" FontSize={"4rem"} FontWeight={"600"}   />
          <Text text="20W60 - CF-4/SL"  />
          <Text Margin={"25px 0px 20px 0px"} FontSize={"1.8rem"}  text="Hagan 20W60 is multi-grade engine oil formulated with basic oils of premium quality and excellent additives that provide good protection and performance. It helps in protection against the wear of valve train and helps in achieving greater intervals of oil drains and keeps the engine clean." />
          <Text FontSize={"1.8rem"} text="This oil is recommended for turbo-charged and non-turbo-charged engines operating under normal conditions and meets all current industry standard requirements of major engine manufacturers that suggest 20W60 and APICF-4/SL . It is recommended for old trucks and buses for effective lubrications." />
        </div>
        <div className="ProductProvides">
        <Text text="HAGAN - 20W-60 CF-4/SL Provides" Margin={"25px 0px 25px 0px"} FontSize={"2.5rem"} FontWeight={"500"} />
        <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
      <Image Src={"/images/DieselProduct/heavy-HP-60-20W60.png"} Width={"65%"}  />
      </div>
    </div>
  );
}
