import React from 'react'
import "./ProductPageContent.css"
import Image from '../Image/Image'

export default function ProductPageContent() {
  return (
    <div className="Product_Page_Content">
      <div className="PageHeaderText">
              <Image Src={"/images/HomePage/haganHeading.svg"} />
              <a className='DownloadProfileLink' href="/images/HAGAN-Catalogue New 2023.pdf" target='blank' ><span> Download</span> Product Profile</a>
            </div>
      </div>
  )
}
