import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function Bike_4T_J_20W50() {
  const ProductProvides = [
    "Provides proper frictional characteristics",
    "Provides excellent wear protection at high speeds and loads",
    "Provides improved acceleration response",
    "Provides easy gear shifting",
    "Provides high temperature protection ensuring maintained oil pressure.",
  ];
  return (
    <div className="Pao-0w20Parent d-flex">
      <div className="ProductDetails" style={{ width: "40%" }}>
      <div className="ProductDetailsInner">
        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/motorcycle_series"
        />
        <div className="ProductSpecificatons">
          <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - 4T-J" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="20W50 - SJ" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="HAGAN SJ (JASO MA) 20W-50 is an advanced four-stroke motorcycle engine oil. It helps in running, cleaning andprotection of 4-stroke engines even in extreme operating conditions."
          />
          <Text
            FontSize={"1.8rem"}
            text="It is a premium 4-storke motorcycle engine oil developed from special high quality base stocks and premium additive packagewhich helps in providing features to fulfill the important requirements of engine. It provides maximum frictional characteristicsthat are required for wet clutch engine."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="HAGAN - 4T-J 20W50  Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
          />
          <ul>
            {ProductProvides.map((item) => (
              <li>
                <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
      <div className="ProductImage">
        <Image Src={"/images/MotorCycleProduct/bike-20W50-SJ.png"} />
      </div>
    </div>
  );
}
