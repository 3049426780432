import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";

export default function HP_40_10w40() {
  const ProductProvides = [
    "It provides longer engine life.",
    "Provides protection against starting friction.",
    "Has good heat management capability.",
    "Protects against deposit accumulation.",
    "Proven anti-wear additive system helps in protection against wear by developing a protective surface on contact layers.",
    "It preserves engine performance with the help of ashless dispersant additive system and provides good control of piston and ringdeposits in extreme conditions.",
    "Excellent thermal and oxidation stability helps in less oil degradation which results in less maintenance cost.",
  ];
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around">
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/minerals"
        />
        <div className="ProductSpecificatons">
          <Text text="Minerals Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - HP-40" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="10W40 - SL/CF" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="HAGAN 10W40 SL/CF is a premium quality, shear-stable, multi-grade gasoline engine oil which is used in wide variety of passenger cars and light duty commercial vehicle gasoline engines. It is recommended for use in gasoline engines where APISL/CF performance oils are best suited. This oil is very efficient in terms of providing protection against starting friction, heat stress management and deposit accumulation in engines."
          />
          <Text
            FontSize={"1.8rem"}
            text="It is best suited for naturally aspirated and turbocharged gasoline engines where API SL/CF or lower performance lubricants are recommended for use. It is also recommended for light truck gasoline engines and four-stroke gasoline engine equipped importable power equipment in which manufacturer recommends oils of API SL/CF or lower performance."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="HAGAN - HP-40  10W-40 SL/CF Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
            FontWeight={"600"}
          />
          <ul>
            {ProductProvides.map((item) => (
              <li>
                <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
        <Image Src={"/images/Minerals/Mineral HP-40.png"} Width={"65%"} />
      </div>
    </div>
  );
}
