import React from "react";
import Button from "../../../Button/Button";
import Text from "../../../Text/Text";
import Image from "../../../Image/Image";


export default function GT_6_5w40() {
  const ProductProvides = [
    "It provides very good fuel economy",
    "The temperature range capability provides very good oil viscosity that reduces friction at start-up",
    "The temperature range capability provides very good oil viscosity that reduces friction at start-up",
    "It’s anti-sludge capability helps in maintaining engine’s power and cleanliness, hence increasing the life of engine",
    "The premium edge viscosity index provides brilliant wear protection",
  ];
  return (
    <div className="Pao-0w20Parent d-flex  justify-content-around" style={{zIndex:"999"}}>
      <div className="ProductDetails">
        <div className="ProductDetailsInner">
        <Button
          BGcolor={"white"}
          Radius={"50%"}
          Width={"50px"}
          Height={"50px"}
          text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
          RouteLink="/product/semi-synthetic-series"
                  />
        <div className="ProductSpecificatons">
          <Text text="Synthetic Seires" Margin={"25px 0px 0px 0px"} />
          <Text text="HAGAN - GT-6" FontSize={"4rem"} FontWeight={"600"} />
          <Text text="10W40 - SP" />
          <Text
            Margin={"25px 0px 20px 0px"}
            FontSize={"1.8rem"}
            text="HAGAN 10W40 SP facilitates the cleanliness of engines and also helps in smooth running of engines, preventing the creation of sludge and engine deposits. It is best suited for a vast category of vehicles of this time period that are driven in demanding and difficult traffic scenarios. It is formed out of synthetic base oils and extreme performing additive systems. Moreover it has very brilliant viscosity index that is helpful in meeting the requirements of current and modern top-tier engines that require API, SN lubricants."
          />
          <Text
            FontSize={"1.8rem"}
            text="It is developed in such a way that is provides best engine oil performance. More importantly it helps in better fuel economy, less friction and more durability. It is suitable for all gasoline fueled engines, high performance car having DOHC, EFI and VVT, four-stroke gasoline motorcycle engines and sports vehicles."
          />
        </div>
        <div className="ProductProvides">
          <Text
            text="Pao Seires Provides"
            Margin={"25px 0px 25px 0px"}
            FontSize={"2.5rem"}
          />
           <ul>
        {ProductProvides.map((item) => (
          <li>
          <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
          </li>
                
            ))}
        </ul>
        </div>
      </div>
      </div>
<div className="ProductImage">
        <Image Src={"/images/SemiSynthetic/GT-6-10W40.png"} Width={"55%"} />
      </div>
    </div>
  );
}
